import {
  Box,
  ContactUsEmailLink,
  Container,
  Heading,
  IconCareRainbow,
  IconClipboard,
  IconFindingWork,
  Intro,
  NavigationTiles,
  PartnerImageTile,
  ReadMoreLink,
  RelatedPagesContent,
  Row,
  Section,
  YouTubeVideo,
  routesObject
} from "@life-without-barriers/react-components"

import ContentLayoutDiversity from "../../../components/social-policy/ContentLayoutDiversity"
import { IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { Site } from "@life-without-barriers/gatsby-common"
import { graphql } from "gatsby"

const {
  OurLGBTIJourneyIcon,
  ReconciliationActionPlan,
  AccessibilityInclusionEmployment
} = RelatedPagesContent

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    ogImage: IGatsbyImageData
    diversityAnod: IGatsbyImageData
    diversityCtiip: IGatsbyImageData
    diversityDca: IGatsbyImageData
    diversityPid: IGatsbyImageData
    diversitySnm: IGatsbyImageData
  }
}

const iconSelector = (
  type: string,
  color: string,
  height?: string,
  width?: string
): JSX.Element | undefined => {
  if (type === "carerainbow") {
    return <IconCareRainbow color={color} height={height} width={width} />
  }
  if (type === "clipboard") {
    return <IconClipboard color={color} height={height} width={width} />
  }
  if (type === "findingwork") {
    return <IconFindingWork color={color} height={height} width={width} />
  }
  return undefined
}

const DiversityAndInclusionPage = ({
  location,
  data: {
    site,
    ogImage,
    diversityAnod,
    diversityCtiip,
    diversityDca,
    diversityPid,
    diversitySnm
  }
}: Props) => (
  <ContentLayoutDiversity
    location={location}
    site={site}
    contentProps={{
      metaTitle: "Diversity and inclusion",
      metaDescription:
        "At Life Without Barriers, we welcome diversity. We actively foster inclusion through the diversity of our people. We live a culture underpinned by respect, belonging and connection.",
      ogImage,
      title: "Diversity and inclusion"
    }}
    breadcrumbs={[
      routesObject.home,
      {
        to: "/social-policy",
        text: "Social policy",
        title: "Return to social policy page"
      }
    ]}
  >
    <div>
      <Container>
        <Row>
          <Box className="layout-readable">
            <Intro text="At Life Without Barriers, we celebrate diversity, and we practice inclusion. We believe in the strength of a diverse workforce where the perspectives and life experiences of our people help us build strong relationships with the people we support." />
          </Box>
        </Row>
      </Container>
      <Section background="bg-lwb-theme-xxx-light">
        <Container>
          <Row>
            <Box className="layout-readable">
              <Heading size={2}>BeYourself@LWB</Heading>
              <p>
                Diversity refers to all the ways in which we differ.{" "}
                <sup>1</sup> This includes culture, ethnicity, faith, age,
                disability, sexual orientation, gender identity/expression,
                intersex status, relationship status, and lived experience.
              </p>
              <p>
                Inclusion occurs when a diversity of people are respected,
                connected, progressing, and contributing to organisational
                success. We asked our employees what it really means to be
                included at Life Without Barriers.
              </p>
              <YouTubeVideo
                title="Be Yourself at LWB: Staff share their inclusion stories"
                videoId="EN1D15YiLv4"
                className="mt4"
              />
            </Box>
          </Row>
        </Container>
      </Section>
      <Container>
        <Row>
          <Box className="layout-readable">
            <Heading size={2}>Our recent work</Heading>
            <p>
              &apos;I Belong&apos;. We explored what it means to belong through
              a series of virtual events. We also invited employees to share
              their diversity information and workplace inclusion experiences
              with us through our first D&I Survey.
            </p>
            <p>
              In January 2021, we released an Inclusive Language Guide to
              promote the use of inclusive language across Life Without
              Barriers. We want the way we communicate with each other, and as
              an organisation, to be respectful and inclusive.
            </p>
          </Box>
        </Row>
      </Container>
      <Section background="bg-hex-image">
        <Container>
          <Row>
            <Box>
              <NavigationTiles
                selectIconFn={iconSelector}
                pages={[
                  ReconciliationActionPlan,
                  AccessibilityInclusionEmployment,
                  OurLGBTIJourneyIcon
                ]}
                currentPathname={location.pathname}
                heading={
                  <Heading size={2} className="color-lwb-theme-medium tc">
                    Want to learn more about our work and plans?
                  </Heading>
                }
              />
            </Box>
          </Row>
        </Container>
      </Section>
      <Section background="bg-lwb-theme-xxx-light">
        <Container>
          <Row>
            <Box className="w-100">
              <Heading size={2} className="color-lwb-theme-medium tc">
                We hold memberships with
              </Heading>
              <div className="flex flex-wrap pt4">
                <PartnerImageTile
                  image={diversityDca}
                  altText="Diversity Council Australia"
                  url="https://www.dca.org.au/"
                />
                <PartnerImageTile
                  image={diversityPid}
                  altText="Pride in Diversity"
                  url="https://www.prideinclusionprograms.com.au/"
                />
                <PartnerImageTile
                  image={diversityAnod}
                  altText="Australian Network on Disability"
                  url="https://www.and.org.au/"
                />
                <PartnerImageTile
                  image={diversitySnm}
                  altText="Supply Nation"
                  url="https://supplynation.org.au/"
                />
                <PartnerImageTile
                  image={diversityCtiip}
                  altText="Career Trackers Indigenous Internship Program"
                  url="https://www.careertrackers.org.au/"
                />
              </div>
            </Box>
          </Row>
        </Container>
      </Section>
      <Container>
        <Row>
          <Box className="layout-readable">
            <Heading size={3} topMargin="none">
              Share your feedback
            </Heading>
            <p>
              We welcome feedback from everyone about our Diversity and
              Inclusion work. Get in touch by sending an email to:
            </p>
            <ContactUsEmailLink
              text="diversity@lwb.org.au"
              link="mailto:diversity@lwb.org.au"
            />
            <Heading size={3}>Careers</Heading>
            <p>
              We want to employ people who reflect the diversity of the people
              we support. Take a look at our latest job opportunities around
              Australia.
            </p>
            <ReadMoreLink
              href={"/careers/"}
              text="Learn more in our Careers section"
              className="mt3 mt4-l"
              rooted
            />
          </Box>
        </Row>
      </Container>
      <Section background="bg-lwb-grey-xxx-light">
        <Container>
          <Row>
            <Box>
              <small>
                <sup>1</sup>{" "}
                <em>
                  Diversity Council Australia, Diversity & Inclusion Definition,
                  Sydney, Diversity Council Australia, 2017
                </em>
              </small>
            </Box>
          </Row>
        </Container>
      </Section>
    </div>
  </ContentLayoutDiversity>
)

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    ogImage: file(relativePath: { regex: "/banner.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1800)
      }
    }
    diversityAnod: file(relativePath: { regex: "/lwb-di-anod.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 175, quality: 90)
      }
    }
    diversityCtiip: file(relativePath: { regex: "/lwb-di-ctiip.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 175, quality: 90)
      }
    }
    diversityDca: file(relativePath: { regex: "/lwb-di-dca.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 175, quality: 90)
      }
    }
    diversityPid: file(relativePath: { regex: "/lwb-di-pid.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 175, quality: 90)
      }
    }
    diversitySnm: file(relativePath: { regex: "/lwb-di-snm.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 175, quality: 90)
      }
    }
  }
`
export default DiversityAndInclusionPage
