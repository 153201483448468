import {
  Banner,
  BreadcrumbItem,
  Helmet,
  Layout,
  Theme
} from "@life-without-barriers/react-components"

import { IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { Site } from "@life-without-barriers/gatsby-common"

export interface ContentLayoutProps {
  location: { pathname: string }
  children: React.ReactElement
  site: Site
  contentProps: ContentProps
  breadcrumbs?: BreadcrumbItem[]
}

interface ContentProps {
  metaTitle: string
  metaDescription: string
  ogImage: IGatsbyImageData
  title: string
  description?: string
}

// todo: replace with normal layout
const ContentLayoutDiversity = ({
  location,
  contentProps: { metaTitle, metaDescription, ogImage, title, description },
  children,
  site,
  breadcrumbs
}: ContentLayoutProps) => (
  <div>
    <Helmet
      title={`${metaTitle} | ${site.siteMetadata.title}`}
      description={metaDescription}
      siteUrl={site.siteMetadata.siteUrl}
      path={location.pathname}
      image={ogImage}
    />
    <Layout theme={Theme.corporateThemeFull}>
      <Banner title={title} subTitle={description} breadcrumbs={breadcrumbs} />
      {children}
    </Layout>
  </div>
)

export default ContentLayoutDiversity
